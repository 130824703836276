import React from "react";
import { useState } from "react";

import './App.css';

import Page1 from './pages/page1/page1.js';
import Page2 from './pages/page2/page2.js';
import Page3 from './pages/page3/page3.js';
import Page4 from './pages/page4/page4.js';

const App = () => {
  const[darkMode,setDarkMode] = useState(true)
    const toggleDarkMode = () => {
        setDarkMode(prevMode => !prevMode);
    };
  
  return (
    <div className="App">
    <link rel="canonical" href="https://www.janstuers.de"/>
      <div className={darkMode ? 'dark-mode' : 'light-mode'}>
        <button className="changeApperanceButton" onClick={toggleDarkMode}>-</button>
        <section id="home">
          <Page1/>
        </section> 
        <section id="about">
          <Page2/>
        </section>
        <section id="skills">
          <Page3/>
        </section>
        <section id="contact">
          <Page4/>
        </section>
        </div>
    </div>
  );
}

export default App;

/*

*/
