import React from 'react';
import {useRef, useEffect} from 'react';

import {gsap} from 'gsap';
import TypeWriter from 'typewriter-effect';

import page1Effect2 from '../../components/effects/page1Effect2';
import page1Effect2mobile from '../../components/effects/page1Effect2mobile';
import NavBar from '../../components/navbar/NavBar';

import './page1.css';


const Page1 = ({url}) => {
    const nameRef = useRef(null);

    if(window.innerWidth>1024){
      page1Effect2(url);
    }else{
      page1Effect2mobile(url);
    }

    useEffect(() => {
      gsap.fromTo(nameRef.current,{x:'-100vw'},{x:0,ease:'power2',duration:1.5});
    },[]);
    
    return(
        <>
          <canvas id = "canvas"/>
          <NavBar/>
          <div className="home-page">
            
            <h1 className="home-page-title" ref={nameRef}>Jan Stuers.</h1>
            <h3 className="home-page-title">
            <TypeWriter
                onInit={(typewriter) => {
                  typewriter
                  .pauseFor(2000)
                  .typeString("IT-Security Student")
                  .start();
                }}
              />
            </h3>
          </div>
          
        </>
    )
}
export default Page1;

/*

*/